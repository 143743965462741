<template>
  <div>
    <a-card title="Kartu Angsuran">
      <div class="row pl-4 pr-4 pt-4 pb-4">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">No Rekening</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <cleave
                :readOnly="false"
                :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                class="ant-input"
                :raw="false"
                style="width: 150px;"
                v-model.lazy="norekening"
                placeholder="Ketik No Rekening"
                v-on:keydown.enter.native="checkRekening"
              />
              <a-select
                label-in-value
                show-search
                class="ml-2"
                :value="selectedName"
                placeholder="Ketikkan Nama Nasabah"
                style="width: 200px;"
                :filter-option="false"
                :not-found-content="fetching ? 'Searching...' : 'Not Found!'"
                @search="fetchUser"
                @change="(e) => handleChange(e, 'selectedName')"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for="(d, index) in dataRekening"
                  :key="index"
                >
                  <!-- {{ d.rekening + ' - ' + d.nama }} -->
                  {{ d.nama + " - " + d.rekening }}
                </a-select-option>
              </a-select>
              <a-button
                class="ml-2"
                @click="carinasabahvisible = !carinasabahvisible"
              >
                <a-icon type="search"></a-icon>
              </a-button>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Nama</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                style=""
                v-model="maskInput.nama"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Alamat</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                style=""
                v-model="maskInput.alamat"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Plafond</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                style=""
                v-model="maskInput.plafond"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Suku Bunga</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                style="width: 100px;"
                v-model="maskInput.sukubunga"
                :readOnly="true"
              ></a-input>
              % per Tahun
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <label class="mt-1">Angsuran Per Bulan</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <a-input
                style=""
                v-model="maskInput.angsuran_perbulan"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Lama</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style="width: 100px;"
                class="mr-2"
                v-model="maskInput.lama"
                :readOnly="true"
              ></a-input>
              Bulan
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Tgl Realisasi</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style="width: 150px;"
                class="mr-2"
                v-model="maskInput.tgl_realisasi"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Jth Tempo Kredit</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style="width: 150px;"
                class="mr-2"
                v-model="maskInput.jthtmp"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">No. PK</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style=""
                class="mr-2"
                v-model="maskInput.nopk"
                :readOnly="true"
              ></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <a-table
            :columns="columnDefs"
            :dataSource="datatable"
            :pagination="{
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <span slot="selected" slot-scope="text, record, index">
              <a-checkbox
                :checked="record.selected"
                @change="(e) => checkBoxChange(e, record, index)"
              >
              </a-checkbox>
              <!-- <a class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a> -->
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <label slot="formatCurrency" slot-scope="text">
              {{ formatCurrency(text) }}
            </label>
            <label slot="ke" slot-scope="text, record, index">
              {{ index + 1 }}
            </label>
            <label slot="tgl" slot-scope="text">
              {{ formatDate(text) }}
            </label>
          </a-table>
        </div>
      </div>
    </a-card>
    <a-modal
      v-model="carinasabahvisible"
      :dialogStyle="{ top: '10px' }"
      :footer="null"
      :width="800"
    >
      <template slot="title">
        <strong>{{ carinasabahtitle }} </strong>
        <label style="margin: unset;" v-if="settings.selectedRekening !== ''">
          | Active Rekening: {{ settings.selectedRekening }}</label
        >
      </template>
      <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
      <div class="row">
        <cleave
          :readOnly="false"
          :options="{ delimiter: '.', blocks: [2, 2, 6] }"
          class="ant-input ml-3"
          :raw="false"
          placeholder="Ketik Rekening"
          v-model="inputModal.alamat"
          style="width: 200px;"
          @keydown.native.enter="findData"
        />
        <a-input
          class="ml-3"
          placeholder="Ketik Nama"
          v-model="inputModal.nama"
          style="width: 200px;"
          @keydown.enter="findData"
        ></a-input>
        <!-- <a-input
          class="ml-2"
          placeholder="Ketik Alamat"
          v-model="inputModal.alamat"
          style="width: 200px;"
          @keydown.enter="findData"
        ></a-input>
        <a-select
          style="width: 70px;"
          placeholder="Kode"
          class="ml-2"
          v-model="inputModal.cabang"
        >
          <a-select-option value="01">01</a-select-option>
        </a-select> -->
        <a-button
          v-if="settings.selectedRekening !== ''"
          class="btn btn-outline-danger btn-sm ml-2"
          @click="clearRekening"
          >Clear Selected Rekening</a-button
        >
        <a-divider type="horizontal" style="margin: 8px 8px;" />
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label
            v-if="
              table.tableatas.datatable.length !== null &&
              table.tableatas.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tableatas.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.tableatas.column"
            :dataSource="table.tableatas.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
          </a-table>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label
            v-if="
              table.tablebawah.datatable.length !== null &&
              table.tablebawah.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tablebawah.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 200 }"
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <span slot="saldo" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          </a-table>
        </div>
      </div>
      <!-- <lou-cari-nasabah /> -->
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import Cleave from 'vue-cleave-component'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    cleave: Cleave,
  },
  // watch: {
  //   'settings.selectedRekening'() {
  //     this.checkRekening()
  //   },
  // },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      fetching: false,
      selectedRekening: [],
      selectedName: [],
      norekening: '',
      dataRekening: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      maskInput: {
        nama: '',
        alamat: '',
        plafond: '',
        sukubunga: '',
        angsuran_perbulan: '',
        lama: '',
        tgl_realisasi: '',
        jthtmp: '',
        nopk: '',
      },
      columnDefs: [
        // {
        //   title: '#',
        //   scopedSlots: { customRender: 'selected' },
        //   width: 70,
        //   fixed: 'left',
        // },
        {
          title: 'Faktur',
          dataIndex: 'faktur',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.faktur
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Ke',
          width: 30,
          dataIndex: 'ke',
          scopedSlots: { customRender: 'ke' },
          // width: 100,
        },
        {
          title: 'Tanggal Bayar',
          width: 100,
          dataIndex: 'tgl',
          scopedSlots: { customRender: 'tgl' },
          // width: 100,
        },
        {
          title: 'Keterangan',
          width: 200,
          dataIndex: 'keterangan',
          scopedSlots: { customRender: 'keterangan' },
          // width: 100,
        },
        {
          title: 'Ags. Bunga',
          width: 70,
          dataIndex: 'bunga',
          scopedSlots: { customRender: 'formatCurrency' },
          align: 'right',
          // width: 70,
        },
        {
          title: 'Ags. Pokok',
          width: 70,
          dataIndex: 'pokok',
          scopedSlots: { customRender: 'formatCurrency' },
          align: 'right',
          // width: 70,
        },
        {
          title: 'Jumlah',
          width: 70,
          dataIndex: 'jumlah',
          scopedSlots: { customRender: 'formatCurrency' },
          align: 'right',
          // width: 100,
        },
        {
          title: 'Denda',
          width: 70,
          dataIndex: 'denda',
          scopedSlots: { customRender: 'formatCurrency' },
          align: 'right',
          // width: 100,
        },
        {
          title: 'Bagi Debet',
          width: 70,
          dataIndex: 'bagidebet',
          scopedSlots: { customRender: 'formatCurrency' },
          align: 'right',
          // width: 100,
        },
        {
          title: 'Kas',
          width: 100,
          dataIndex: 'kas',
          scopedSlots: { customRender: 'kas' },
          // width: 100,
        },
      ],
      datatable: [],

      // Modal Property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              // width: 100,
            },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
      },
    }
  },
  created() {},
  methods: {
    moment,
    checkBoxChange(e, record, index) {},
    async checkRekening() {
      // console.log('this.norekening.length', this.norekening.length)
      if (this.norekening.length === 12) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet2(
          'report/kredit/kartu_angsuran?Rekening=' + this.norekening.toString(),
        )
        if (res) {
          if (res.data.length !== 0) {
            // lou.shownotif('Ditemukan!', 'Data telah ditemukan!', 'info')
            this.dataRekening = [res.data]
            var value = this.dataRekening.findIndex(
              (x) => x.rekening === this.norekening,
            )
            var dataTarget = this.dataRekening[value]
            var data = {
              selectedName: { key: 0, label: dataTarget.nama },
              selectedRekening: { key: 0, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              tabungan_id: dataTarget.id,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)
            // console.log('dataTarget', dataTarget)

            this.maskInput.nama = dataTarget.nama
            this.maskInput.alamat = dataTarget.alamat
            this.maskInput.plafond = this.formatCurrency(dataTarget.plafond)
            this.maskInput.sukubunga = this.formatCurrency(
              dataTarget.sukubunga,
            )
            this.maskInput.angsuran_perbulan = this.formatCurrency(
              dataTarget.angsuranperbulan,
            )
            this.maskInput.lama = dataTarget.jw
            this.maskInput.tgl_realisasi = moment(dataTarget.tgl).format(
              'DD-MM-YYYY',
            )
            this.maskInput.jthtmp = moment(dataTarget.jthtmp).format(
              'DD-MM-YYYY',
            )
            this.maskInput.nopk = dataTarget.no_spk
            this.datatable = dataTarget.buku_angsurans
          } else {
            lou.shownotif(
              'Tidak di temukan',
              'Tidak ada data dengan rekening tersebut!',
              'error',
            )
          }
        } else {
          // lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
        }
      }
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    async fetchUser(value) {
      // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet2(
            'report/kredit/kartu_angsuran?Nama=' + nvalue,
          )
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet2(
            'report/kredit/kartu_angsuran?Rekening=' + nvalue1,
          )
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    handleChange(value, from) {
      // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        var data = {
          selectedName: value,
          selectedRekening: value,
          rekeningjurnal: dataTarget.rekening,
          norekening: dataTarget.rekening,
          tabungan_id: dataTarget.id,
          namaprekening: dataTarget.nama,
          alamatprekening: dataTarget.alamat,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
        this.maskInput.nama = dataTarget.nama
        this.maskInput.alamat = dataTarget.alamat
        this.maskInput.plafond = this.formatCurrency(dataTarget.plafond)
        this.maskInput.sukubunga = this.formatCurrency(dataTarget.sukubunga)
        this.maskInput.angsuran_perbulan = this.formatCurrency(
          dataTarget.angsuranperbulan,
        )
        this.maskInput.lama = dataTarget.jw
        this.maskInput.tgl_realisasi = moment(dataTarget.tgl).format(
          'DD-MM-YYYY',
        )
        this.maskInput.jthtmp = moment(dataTarget.jthtmp).format('DD-MM-YYYY')
        this.maskInput.nopk = dataTarget.no_spk
        this.datatable = dataTarget.buku_angsurans
      }
    },
    resetForm() {
      this.input = {}
      this.maskInput = {}
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'batalpencairan_kredit?rekening=' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        console.log('res', res)
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
          // this.maskInput = this.$g.clone(res.data)
          console.log('this.maskInput', this.maskInput)
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    formatCurrency(v) {
      return v === '' || v === null || v === undefined ? 0 : lou.curency(v)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    formatBoolean(v) {
      if (v) {
        return 'Ya'
      } else {
        return 'Tidak'
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Rekening=' + this.inputModal.alamat
            : '?Rekening=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2(
        'report/kredit/kartu_angsuran' + imbuhan,
      )
      if (res) {
        this.table.tablebawah.datatable =
          this.inputModal.alamat !== '' ? [res.data] : res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        // this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            var data = {
              selectedName: { key: 0, label: record.nama },
              selectedRekening: { key: 0, label: record.rekening },
              rekeningjurnal: record.rekening,
              norekening: record.rekening,
              tabungan_id: record.id,
              namaprekening: record.nama,
              alamatprekening: record.alamat,
              saldoprekening: record.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)
            // console.log('record', record)

            this.maskInput.nama = record.nama
            this.maskInput.alamat = record.alamat
            this.maskInput.plafond = this.formatCurrency(record.plafond)
            this.maskInput.sukubunga = this.formatCurrency(record.sukubunga)
            this.maskInput.angsuran_perbulan = this.formatCurrency(
              record.angsuranperbulan,
            )
            this.maskInput.lama = record.jw
            this.maskInput.tgl_realisasi = moment(record.tgl).format(
              'DD-MM-YYYY',
            )
            this.maskInput.jthtmp = moment(record.jthtmp).format('DD-MM-YYYY')
            this.maskInput.nopk = record.no_spk
            this.datatable = record.buku_angsurans
            this.carinasabahvisible = false
            // this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },

    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped></style>
